//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LookForm from "../../components/lookForm";
import LookFormApproval from "../../components/lookFormApproval";
//
// import applyDetail from "@/lib/data-service/haolv-default/consumer_apply_detail";
import consumer_apply_workflowDetail from '@/lib/data-service/haolv-default/consumer_apply_workflowDetail';

// 出差单审批 + 审批预订详情 1 2
import consumer_apply_detail from "@/lib/data-service/haolv-default/consumer_apply_detail";

// 超标审批详情 2
import consumer_overproof_detail from "@/lib/data-service/haolv-default/consumer_overproof_detail";

// 改签审批详情 4
import change_detail from "@/lib/data-service/haolv-default/consumer_journey_getApplyChange";

// 退订审批详情 5
import refund_detail from "@/lib/data-service/haolv-default/consumer_journey_getApplyRefund";
import {operablePermissions, getProcessInstanceInfo} from "@/lib/data-service/haolv-default/design";

export default {
    name: "ApproveInfo",
    data() {
        return {
            loading: false,
            type: "",
            detailData: {},
            approveType: null,
            opaList: [], // 操作按钮权限
            processInfo: {}, // 提交操作表单
        };
    },
    components: { LookFormApproval,LookForm },
    methods: {
        getDetail() {
            this.loading = true;
            const id = this.$route.query.applyId || this.$route.query.id;
            const applyNo = this.$route.query.applyNo || '';
            const  listApproveStatus =  Number(this.$route.query.listApproveStatus || '0');
            const  approveStatus =  Number(this.$route.query.approveStatus || '0');
            const approveType = Number(this.$route.query.approveType);

            // approveType 审批类型：1.出差申请，2报销申请，3.超标申请，4.改签，5.退票
            // approveType 审批类型：1.出差申请，超标申请，，4.改签，5.退票
            const appType = Number(this.$route.query.appType); // 1.我的申请 2待我审批
            if (approveType === 2) {
                consumer_overproof_detail({ applyId: id, applyNo, appType, approveStatus: listApproveStatus })
                    .then((res) => {
                        const datas = res.datas;
                        const evection = datas.evectionDetailResponse;

                        datas.evection = evection
                        datas.staffDetailVos = datas.evectionDetailResponse.travellerList
                        datas.initialName = evection.initialName
                        datas.overproofs = {
                            applyNo: datas.applyNo,
                            applyName: datas.applyName,
                            feeAffiliationName: evection.feeAffiliationName,
                            overproofList: datas.overproofs,
                        }

                        // 审批类型 1-酒店退 2-机票退 3火车票退 4机票改 5火车票改
                        this.detailData = datas;
                        this.loading = false;
                        this.processInfo = {
                            processInstanceId: applyNo,
                            taskId: res.datas.taskId,
                            signFlag: null,
                            currentUserInfo: {
                                id: this.$store.state.userInfo.id,
                                name: this.$store.state.userInfo.realName,
                            },
                        }

                        // 只有审批中才能调
                        if (res.datas.status === 1 || res.datas.status === 3) {
                            operablePermissions({processInstanceId: applyNo, userId: this.$store.state.userInfo.id, taskId: res.datas.taskId}).then((result)=>{
                                this.opaList = result.result;
                            })
                        }
                        getProcessInstanceInfo(applyNo, res.datas.taskId).then(resultInfo=>{
                            const formData = { ...resultInfo.result.formData };
                            console.log('formData', formData);
                            this.processInfo.formData = formData;
                        })
                        if (this.$store.state.workflow === '1') {
                            this.$nextTick(()=>{
                                this.$refs.lookForm.getFlow();
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
            } else if (approveType === 4 || approveType === 8) {
                change_detail({ id, applyNo, appType, approveStatus: listApproveStatus })
                    .then((res) => {
                        const detail = res.datas;
                        // 审批类型 1-酒店退 2-机票退 3火车票退 4机票改 5火车票改
                        const type = Number(detail.type);
                        if (type === 4) {

                        } else if (type === 5) {

                        }
                        this.detailData = detail;
                        this.loading = false;
                        if (this.$store.state.workflow === '1') {
                            this.processInfo = {
                                processInstanceId: applyNo,
                                taskId: res.datas.approveApply.taskId,
                                signFlag: null,
                                currentUserInfo: {
                                    id: this.$store.state.userInfo.id,
                                    name: this.$store.state.userInfo.realName,
                                },
                            }

                            // 只有审批中才能调
                            if (res.datas.approveApply.status === 1 || res.datas.approveApply.status === 3) {
                                operablePermissions({processInstanceId: applyNo, userId: this.$store.state.userInfo.id, taskId: res.datas.approveApply.taskId}).then((result)=>{
                                    this.opaList = result.result;
                                })
                            }
                            getProcessInstanceInfo(applyNo, res.datas.approveApply.taskId).then(resultInfo=>{
                                const formData = { ...resultInfo.result.formData };
                                console.log('formData', formData);
                                this.processInfo.formData = formData;
                            })
                            this.$nextTick(()=>{
                                if (approveType === 4) {
                                    console.log('22222')
                                    this.$refs.lookForm.getFlow();
                                }
                                if (approveType === 8) {
                                    this.$refs.LookFormApproval.getFlow();
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
            } else if (approveType === 5 || approveType === 9) {
                console.log('1');
                refund_detail({ id, applyNo, appType, approveStatus: listApproveStatus })
                    .then((res) => {
                        console.log('2');
                        const detail = res.datas; // 审批类型 1-酒店退 2-机票退 3火车票退 4机票改 5火车票改
                        const type = Number(detail.type);
                        if (type === 1) {

                        } else if (type === 2) {

                        } else if (type === 3) {

                        }
                        this.detailData = detail;
                        this.loading = false;
                        console.log('3');
                        if (this.$store.state.workflow === '1') {
                            console.log('4');
                            this.processInfo = {
                                processInstanceId: applyNo,
                                taskId: res.datas.approveApply.taskId,
                                signFlag: null,
                                currentUserInfo: {
                                    id: this.$store.state.userInfo.id,
                                    name: this.$store.state.userInfo.realName,
                                },
                            }
                            console.log('5', res.datas.approveApply.status);
                            // 只有审批中才能调
                            if (res.datas.approveApply.status === 1 || res.datas.approveApply.status === 3) {
                                console.log('6');
                                operablePermissions({processInstanceId: applyNo, userId: this.$store.state.userInfo.id, taskId: res.datas.approveApply.taskId}).then((result)=>{
                                    this.opaList = result.result;
                                })
                            }
                            getProcessInstanceInfo(applyNo, res.datas.approveApply.taskId).then(resultInfo=>{
                                const formData = { ...resultInfo.result.formData };
                                console.log('formData', formData);
                                this.processInfo.formData = formData;
                            })
                            this.$nextTick(()=>{
                                if (approveType === 5) {
                                    console.log('22222')
                                    this.$refs.lookForm.getFlow();
                                }
                                if (approveType === 9) {
                                    this.$refs.LookFormApproval.getFlow();
                                }
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
            } else if (approveType === 7) {
                let detailApi = this.$store.state.workflow === '1' ? consumer_apply_workflowDetail({applyNo: applyNo, appType, approveStatus: listApproveStatus}) : consumer_apply_detail({id});
                detailApi.then((res) => {
                    this.detailData = res.datas;
                    this.loading = false;

                    if (this.$store.state.workflow === '1') {
                        this.processInfo = {
                            processInstanceId: applyNo,
                            taskId: res.datas.approveApply.taskId,
                            signFlag: null,
                            currentUserInfo: {
                                id: this.$store.state.userInfo.id,
                                name: this.$store.state.userInfo.realName,
                            },
                        }

                        // 只有审批中才能调
                        if (res.datas.approveApply.status === 1 || res.datas.approveApply.status === 3) {
                            operablePermissions({processInstanceId: applyNo, userId: this.$store.state.userInfo.id, taskId: res.datas.approveApply.taskId}).then((result)=>{
                                this.opaList = result.result;
                            })
                        }
                        getProcessInstanceInfo(applyNo, res.datas.approveApply.taskId).then(resultInfo=>{
                            const formData = { ...resultInfo.result.formData };
                            console.log('formData', formData);
                            this.processInfo.formData = formData;
                        })

                        this.$nextTick(()=>{
                            this.$refs.LookFormApproval.getFlow();
                        });
                    }
                })
                    .catch((err) => {
                        this.loading = false;
                    });
            } else {
                // appType: 1.我的申请 2待我审批
                let detailApi = this.$store.state.workflow === '1' ? consumer_apply_workflowDetail({applyNo: applyNo, appType: 2, approveStatus: listApproveStatus}) : consumer_apply_detail({id});
                detailApi.then((res) => {
                    this.detailData = res.datas;
                    this.loading = false;

                    if (this.$store.state.workflow === '1') {
                        this.processInfo = {
                            processInstanceId: applyNo,
                            taskId: res.datas.approveApply.taskId,
                            signFlag: null,
                            currentUserInfo: {
                                id: this.$store.state.userInfo.id,
                                name: this.$store.state.userInfo.realName,
                            },
                        }
                        // 只有审批中才能调
                        if (res.datas.approveApply.status === 1 || res.datas.approveApply.status === 3) {
                            operablePermissions({
                                processInstanceId: applyNo,
                                userId: this.$store.state.userInfo.id,
                                taskId: this.detailData.approveApply.taskId,
                            }).then((result) => {
                                this.opaList = result.result || [];
                            })
                        }
                        getProcessInstanceInfo(applyNo, res.datas.approveApply.taskId).then(resultInfo=>{
                            const formData = { ...resultInfo.result.formData };
                            console.log('formData', formData);
                            this.processInfo.formData = formData;
                        })

                        this.$nextTick(()=>{
                            this.$refs.lookForm.getFlow();
                        });
                    }
                })
                    .catch((err) => {
                        this.loading = false;
                    });
            }
        },
    },
    created() {},

    beforeRouteEnter(to, from, next) {
        const type = to.query.type;
        if (type === "audit") {
            to.meta.lastMenu = "adminNoApprove";
        } else {
            to.meta.lastMenu = "adminHaveApprove";
        }
        next();
    },
    mounted() {},
    async activated() {
        console.log('-----------')
        debugger
        this.type = this.$route.query.type || "";
        this.approveType = Number(this.$route.query.approveType);
        await this.$store.state.workflowDefer.promise;
        await this.$store.state.userInfoDefer.promise;
        this.getDetail();
    },
    watch: {},
    computed: {},
};
